export type Explorer = 'Allo' | 'Lora' | 'PeraExplorer'

export enum AlgorandNetwork {
  LocalNet = 'localnet',
  BetaNet = 'betanet',
  TestNet = 'testnet',
  MainNet = 'mainnet'
}

export interface ExplorerBaseUrls {
  localnet?: string
  betanet?: string
  testnet?: string
  mainnet: string
}

export interface ExplorerPaths {
  accountUrl: string
  transactionUrl: string
  groupUrl: string
  assetUrl: string
  appUrl: string
  blockUrl: string
}

export interface ExplorerConfig {
  baseUrls: ExplorerBaseUrls
  paths: ExplorerPaths
}

export const EXPLORERS: Explorer[] = ['Allo', 'Lora', 'PeraExplorer']

export type ExplorerData = {
  [key in Explorer]: ExplorerConfig
}

export const EXPLORER_DATA: ExplorerData = {
  Allo: {
    baseUrls: {
      mainnet: 'https://allo.info'
    },
    paths: {
      accountUrl: '/account',
      transactionUrl: '/tx',
      groupUrl: '/tx/group',
      assetUrl: '/asset',
      appUrl: '/application',
      blockUrl: '/block'
    }
  },
  Lora: {
    baseUrls: {
      localnet: 'https://lora.algokit.io/localnet',
      betanet: 'https://lora.algokit.io/betanet',
      testnet: 'https://lora.algokit.io/testnet',
      mainnet: 'https://lora.algokit.io/mainnet'
    },
    paths: {
      accountUrl: '/account',
      transactionUrl: '/transaction',
      groupUrl: '/block/{block}/group/{group}',
      assetUrl: '/asset',
      appUrl: '/application',
      blockUrl: '/block'
    }
  },
  PeraExplorer: {
    baseUrls: {
      testnet: 'https://testnet.explorer.perawallet.app',
      mainnet: 'https://explorer.perawallet.app'
    },
    paths: {
      accountUrl: '/account',
      transactionUrl: '/tx',
      groupUrl: '/tx-group',
      assetUrl: '/asset',
      appUrl: '/application',
      blockUrl: '/block'
    }
  }
}

export type DefaultExplorers = {
  [key in AlgorandNetwork]: Explorer
}

export const DEFAULT_EXPLORERS: DefaultExplorers = {
  mainnet: 'Allo',
  testnet: 'PeraExplorer',
  betanet: 'Lora',
  localnet: 'Lora'
}
