import { ALGORAND_NETWORK } from 'src/data/constants'
import { EXPLORER_DATA, Explorer, ExplorerConfig, ExplorerPaths } from 'src/data/explorers'

enum AlgorandNetwork {
  BetaNet = 'betanet',
  TestNet = 'testnet',
  MainNet = 'mainnet'
}

export class ExplorerLink {
  private baseUrl: string
  private paths: ExplorerPaths

  constructor(explorer: Explorer) {
    if (!EXPLORER_DATA[explorer]) {
      throw new Error(`Invalid explorer: ${explorer}`)
    }

    const config = EXPLORER_DATA[explorer]
    this.baseUrl = this.getBaseUrl(config)
    this.paths = config.paths
  }

  private getBaseUrl(config: ExplorerConfig): string {
    switch (ALGORAND_NETWORK as AlgorandNetwork) {
      case AlgorandNetwork.BetaNet:
        return config.baseUrls.betanet || config.baseUrls.mainnet
      case AlgorandNetwork.TestNet:
        return config.baseUrls.testnet || config.baseUrls.mainnet
      default:
        return config.baseUrls.mainnet
    }
  }

  account(address: string): string {
    return `${this.baseUrl}${this.paths.accountUrl}/${encodeURIComponent(address)}`
  }

  tx(id: string): string {
    return `${this.baseUrl}${this.paths.transactionUrl}/${encodeURIComponent(id)}`
  }

  group(id: string, block: string | number): string {
    const path = this.paths.groupUrl
    if (path.includes('{block}') || path.includes('{group}')) {
      return `${this.baseUrl}${path
        .replace('{block}', encodeURIComponent(block))
        .replace('{group}', encodeURIComponent(id))}`
    }
    return `${this.baseUrl}${path}/${encodeURIComponent(id)}`
  }

  asset(id: string | number): string {
    return `${this.baseUrl}${this.paths.assetUrl}/${encodeURIComponent(id)}`
  }

  app(id: string | number): string {
    return `${this.baseUrl}${this.paths.appUrl}/${encodeURIComponent(id)}`
  }

  block(id: string | number): string {
    return `${this.baseUrl}${this.paths.blockUrl}/${encodeURIComponent(id)}`
  }
}
